<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <!-- <div class="area_left">
          <strong class="tit_tbl">공지사항 상세</strong>
        </div> -->
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>라운드</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.round }}</span>
          </td>
          <th>UID</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.uid }}</span>
          </td>
        </tr>
        <tr>
          <th>상태</th>
          <td>
            <span class="txt_tbl">
              <Badge
                :text="viewModel.model.status | convertIdToText('common_status')"
                :badgeStyle="viewModel.model.status | convertIdToColor('common_status')"
                :badgeFill="false" />
            </span>
          </td>
          <th>레이스 타입</th>
          <td>
            <span class="txt_tbl">
              <Badge
                :text="viewModel.model.raceType | convertIdToText('stock_game_race_type')"
                :badgeStyle="viewModel.model.raceType | convertIdToColor('stock_game_race_type')"
                :badgeFill="false" />
            </span>
          </td>
        </tr>
        <tr>
          <th>접수 기한</th>
          <td colspan="3">
            <span class="txt_tbl">{{ viewModel.model.receiptStartAt | convertDateFormat() }} ~</span> <span :class="['txt_tbl',checkPastDate(viewModel.model.receiptEndAt, 'tc_red')]">{{ viewModel.model.receiptEndAt | convertDateFormat() }}</span>
          </td>
        </tr>
        <tr>
          <th>레이스 기한</th>
          <td colspan="3">
            <span class="txt_tbl">{{ viewModel.model.raceStartAt | convertDateFormat() }} ~</span> <span :class="['txt_tbl',checkPastDate(viewModel.model.raceEndAt, 'tc_red')]">{{ viewModel.model.raceEndAt | convertDateFormat() }}</span>
          </td>
        </tr>
        <tr>
          <th>모의투자금</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.feeAmount | convertNumberToComma() }}</span>
          </td>
          <th>화폐단위</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.currencyCode }}</span>
          </td>
        </tr>
        <tr>
          <th rowspan="2">리워드 이미지</th>
          <td rowspan="2">
            <img
              v-if="viewModel.model.rewardImgUrl"
              :src="viewModel.model.rewardImgUrl"
              class="img_g">
            <span v-else class="txt_tbl">-</span>
          </td>
          <th>리워드</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.reward }}</span>
          </td>
        </tr>
        <tr>
          <th>리워드 상세</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.rewardDetails }}</span>
          </td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="목록"
          routeName="STOCK_GAME_LIST"
          :routeQuery="{ pageSize : $route.query.pageSize, page : $route.query.page }"/>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="수정"
          routeName="STOCK_GAME_MODIFY"
          :routeQuery="{ pageSize : $route.query.pageSize, page : $route.query.page }"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>
<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Badge from '@lemontree-ai/lemontree-admin-common-front/components/common/badge/Badge';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';

import { checkPastDate } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';

// viewModel
import StockGameDetailViewModel from '@/views/stock/game/viewModel/StockGameDetailViewModel'

export default {
  name: 'StockGameDetail',
  components: {
    PageWithLayout,
    TableView,
    Badge,
    Sticky,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id;
    this.viewModel.init(id);
  },
  data(){
    return{
      viewModel: new StockGameDetailViewModel(),
    }
  },
  methods:{
    checkPastDate(date, returnText){
      return checkPastDate(date, returnText);
    }
  }
}
</script>

<style scoped>
</style>
